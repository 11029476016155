import React from "react";
import servicesbg from "../assets/servicesbg.jpg";
import SelectsService from "./SelectsCard";
import {FaMapMarkedAlt} from "react-icons/fa";
import {FaPlaneDeparture} from "react-icons/fa";
import {FaRegPaperPlane} from "react-icons/fa";
import {FaHotel} from "react-icons/fa";
import "./Styles.css";

const Selects = () => {
	return (
		<div id="Travel" className=" mt-4 w-full mx-auto ">
			<div id="Services" className="relative py-2">
				<div className="title-wrap relative bottom-24 flex">
					<h2 className="lg-title absolute z-10 inset-0 w-full flex justify-center ">
						Our services
					</h2>
				</div>
				<SelectsService
					className="service_image relative max-h-50"
					bg={servicesbg}
				></SelectsService>
				<div className="absolute services-row lg-title inset-0 w-full flex justify-center">
					<div className="services-item services_thirt z-10">
						<div className="services-icon">
							<FaPlaneDeparture className="airplane_icon"></FaPlaneDeparture>
						</div>
						<h3>Travel</h3>
						<div>
							<span>On time to </span>
							<span> new places.</span>
						</div>
						<div className="arrowInside">
							<a href="/#" className="btn">
								<FaRegPaperPlane className="fly__icon"></FaRegPaperPlane>
								<span className="icon_text">Get on</span>
							</a>
						</div>
					</div>
					<div className="services-item services_first z-10 ">
						<div className="services-icon">
							<FaMapMarkedAlt className="map__icon"></FaMapMarkedAlt>
						</div>
						<h3>Adventure</h3>
						<div className="services-general">
							<span>Get to know</span>
							<span> Brasil.</span>
							<div className="arrowInside1">
								<a href="/#" className="btn">
									<FaRegPaperPlane className="fly__icon"></FaRegPaperPlane>
									<span className="icon_text">Get on</span>
								</a>
							</div>
						</div>
					</div>

					<div className="services-item services_thirt z-10 ">
						<div className="services-icon">
							<FaHotel className="hotel_icon"></FaHotel>
						</div>
						<h3>Hotel</h3>
						<div>
							<span>5 Star hotel</span> <span> booking.</span>
						</div>
						<div className="arrowInside2">
							<a href="/#" className="btn">
								<FaRegPaperPlane className="fly__icon"></FaRegPaperPlane>
								<span className="icon_text">Get on</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Selects;
