import React from "react";
import {FaFacebook, FaTwitter, FaInstagram, FaPinterest, FaYoutube} from "react-icons/fa";

const Footer = () => {
	return (
		<div id="Contact" className="w-full bg-gray-100 py-16">
			<div className="max-w-[1240px] mx-auto flex flex-col px-4">
				<div className="sm:flex text-center justify-between items-center">
					<h1>BRASIL</h1>
					<div className="flex justify-between w-full sm:max-w-[280px] my-4">
						<FaFacebook color="#000" className="icon" />
						<FaTwitter color="#000" className="icon" />
						<FaYoutube color="#000" className="icon" />
						<FaPinterest color="#000" className="icon" />
						<FaInstagram color="#000" className="icon" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
