import React from "react";

import BoraBora2 from "../assets/borabora2.jpg";
import Maldives from "../assets/maldives.jpg";
import Maldives2 from "../assets/maldives2.jpg";
import SelectsCard from "./SelectsCard";

import "./Styles.css";

const Selects = () => {
	return (
		<div id="Places" className="  mx-auto px-4 py-5 grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
			<a href="/#" className="selectCard1 ">
				<SelectsCard bg={BoraBora2} text="Maldives" />
			</a>
			<a href="/#" className="selectCard2 ">
				<SelectsCard bg={Maldives} text="Antigua" />
			</a>

			<a href="/#" className="selectCard3 ">
				<SelectsCard className="selectCard3" bg={Maldives2} text="Cozumel" />
			</a>
		</div>
	);
};

export default Selects;
