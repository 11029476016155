import React from "react";
import {AiOutlineSearch} from "react-icons/ai";
import brasil from "../assets/brasil.jpg";

import "./Styles.css";

const Hero = () => {
	return (
		<div id="Home" className="w-full h-screen relative ">
			<img className="w-full h-full object-cover" src={brasil} alt="/" />
			<div className="absolute w-full h-full top-0 left-0 bg-gray-900/30"></div>
			<div className="absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4">
				<div className="home__scroll">
					<a href="#Services" className="home__scroll-button button--flex">
						<i className="home__scroll-mouse"></i>
					</a>
				</div>
				{/* <form
					className="flex justify-between items-center max-w-[700px] mx-auto w-full border p-1
          rounded-md text-black bg-gray-100/90"
				>
					<div>
						<input
							className="bg-transparent w-[300px] sm:w-[400px] font-[Poppins] focus:outline-none
                  "
							type="text"
							placeholder="Search Destinations"
						/>
					</div> 
					<div>
						<button>
							<AiOutlineSearch size={20} className="icon" style={{color: "#ffffff"}} />
						</button>
					</div>
				</form>*/}
			</div>
		</div>
	);
};

export default Hero;
