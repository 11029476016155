import React from "react";
import {AiOutlineSearch} from "react-icons/ai";
import viewer from "../assets/viewer.mp4";

const Viewer = () => {
	return (
		<div className="w-full h-full npm start relative">
			<video className="w-full object-cover" src={viewer} autoPlay loop muted />
		</div>
	);
};

export default Viewer;
