import React, {useState} from "react";
import {BiSearch} from "react-icons/bi";
import {AiOutlineClose} from "react-icons/ai";
import {HiOutlineMenuAlt4} from "react-icons/hi";
import {FaFacebook, FaTwitter, FaInstagram, FaPinterest, FaYoutube} from "react-icons/fa";

const Navbar = () => {
	const [nav, setNav] = useState(false);
	const [logo, setLogo] = useState(false);
	const handleNav = () => {
		setNav(!nav);
		setLogo(!logo);
	};

	return (
		<div className="background_nav flex w-full justify-between items-center h-20 px-4 absolute z-10 text-white">
			<div>
				<h1>
					<a href="/#">BRASIL</a>
				</h1>
			</div>
			<ul className="hidden md:flex">
				<li>
					<a href="#Home">Home</a>
				</li>
				{/* <li>
					<a href="#Destinations">Destinations</a>
				</li> */}
				<li>
					<a href="#Services">Services</a>
				</li>

				<li>
					<a href="#Places">Places</a>
				</li>
				<li>
					<a href="#Contact">Contact</a>
				</li>
				<li className="hidden md:flex">
					<a href="/#">
						<BiSearch className="search" />
					</a>
				</li>
			</ul>{" "}
			{/* Hamburger */}
			<div onClick={handleNav} className="md:hidden z-10">
				{nav ? (
					<AiOutlineClose className=" cursor-pointer text-black" size={30} />
				) : (
					<HiOutlineMenuAlt4 className="cursor-pointer" size={40} />
				)}
			</div>
			{/* Mobile menu dropdown */}
			<div
				onClick={handleNav}
				className={
					nav
						? "absolute text-black left-0 top-0 w-full bg-gray-100/70 px-4 py-7 flex flex-col"
						: "absolute left-[-100%]"
				}
			>
				<ul>
					<h1 className="title_mobile">BRASIL</h1>
					<div className="flex-column justify-between my-6">
						<ul className="menu_mobile">
							<li>
								<a href="#Home">Home</a>
							</li>
							<li>
								<a href="#Services">Services</a>
							</li>
							<li>
								<a href="#Places">Places</a>
							</li>
							<li>
								<a href="#Contact">Contact</a>
							</li>
						</ul>
					</div>
				</ul>
			</div>
		</div>
	);
};

export default Navbar;
